import React from 'react'
import Layout from '../layouts'
import { Table } from 'reactstrap'
import Contact from '../components/contact/contact.js'
import HeroSlider from '../components/hero/truckslider.js'
import WrappedSlider from '../components/hero/fullywrappedslider.js'
import MapTruck from '../images/maptruck-v2.png'

class LunchTruckPage extends React.Component {
	render() {
		return (
			<Layout head={{ title: 'Lunch Truck Advertising' }}>
				<section id="title">
					<div className="container">
						<div className="row">
							<div className="col text-center">
								<h1 className="heading-title">Lunch Truck Advertising</h1>
							</div>
						</div>
					</div>
				</section>

				{/* START OF TRIMEX OUTDOOR LUNCH TRUNK NETWORK */}
				<section id="lunchtruck" className="text-center">
					<div className="container">
						<div className="row">
							<div className="col">
								<h4 className="green">
									<b>
										Trimex Outdoor's Lunch Truck Network operates Nationally in
										42 U.S. Markets and includes over 2,000 Lunch Trucks coast
										to coast. With every $1 spent on OOH media generating $5.97
										of revenue{' '}
										<span style={{ fontSize: `14px` }}>(source OAAA)</span>, our
										network offers an affordable and effective option for
										reaching the blue collar demographic, including
										construction, manufacturing, industrial and farm workers!
									</b>
								</h4>
								<img
									src={MapTruck}
									className="img-fluid img-border"
									alt="Truck"
								/>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col">
								<h2 className="section-title">
									OUR STANDARD LUNCH TRUCK ADVERTISING CAMPAIGNS FEATURE 3 KEY
									TOUCHPOINTS:
								</h2>
								<h3 className="mb-4 text-left">
									<ol>
										<li className="mb-2">
											Large 30" x 60" Exterior Banner Sign
										</li>
										<li className="mb-2">8" x 8" or 18" x 24" Interior Ad</li>
										<li className="mb-2">
											Take-One Box (printed brochures included)
										</li>
									</ol>
								</h3>
							</div>
						</div>
					</div>
				</section>
				{/* END OF TRIMEX OUTDOOR LUNCH TRUNK NETWORK */}

				{/* START OF LUNCH TRUCK SLIDER */}
				<div className="container mb-5">
					<HeroSlider />
				</div>
				{/* END OF LUNCH TRUCK SLIDER*/}

				{/* START OF FULLY WRAPPED TRUCKS AVAILABLE TOO! SECTION */}
				<section className="text-center bg-offwhite mb-2">
					<h1 className="section-title mb-5">
						FULLY WRAPPED TRUCKS AVAILABLE TOO!
					</h1>
					<div className="container">
						<WrappedSlider />
					</div>
				</section>
				{/* END OF FULLY WRAPPED TRUCKS AVAILABLE TOO! SECTION */}

				{/* START OF CIRCULATION SECTION */}
				<section className="text-center bg-white mb-2">
					<div className="container">
						<div className="row">
							<div className="col">
								<h2 className="section-title">
									CIRCULATION: Here is how we derive our circulation figures...
								</h2>
								<p className="section-p">
									Regarding our DEC... We use two circulation figures to arrive
									at our national average of 9,000.
								</p>
								<p className="section-p">
									1) Lunch Trucks Customers; the average truck will serve 500
									customers every working day.
								</p>
								<p className="section-p">
									2) When counting traffic and pedestrians we use the below grid
									to estimate our DEC . This grid was taken from an article
									published by the OAAA that discussed circulation figures for
									mobile vehicles that display ad signage, i.e. taxis, transit,
									wrapped passenger cars, food trucks, delivery trucks/vans.
								</p>
								<p className="section-p">
									If you combine the 4 driving situations below the average DEC
									is 10,488 or 1,311 impression per hour. We know that the
									typical route lasts around 6.5 hours. Take 6.5 hours x 1,311
									hourly DEC you come up with 8,521 + 500 Food Truck Customers
									equates to roughly 9,000. That is how our DEC is figured.
								</p>
								<Table className="table-responsive-sm table table-bordered">
									<thead className="bg-success text-white">
										<tr>
											<th>Driving Situation</th>
											<th>Hours Per Day</th>
											<th>*Passing Vehicles</th>
											<th>**Impressions Per hour</th>
											<th>Daily Totals</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">City</th>
											<td>8</td>
											<td>12000</td>
											<td>2070</td>
											<td>16560</td>
										</tr>
										<tr className="table-success">
											<th scope="row">Residential</th>
											<td>8</td>
											<td>9600</td>
											<td>1656</td>
											<td>13248</td>
										</tr>
										<tr>
											<th scope="row">Parked</th>
											<td>8</td>
											<td>2400</td>
											<td>207</td>
											<td>1656</td>
										</tr>
									</tbody>
								</Table>
								<p>
									Based on # of cars per mile – 15 highway / 40 surface street
								</p>
								<p>Using TAB vehicle load factor of 1.38 adults per vehicle</p>
								<p>Includes pedestrians</p>
							</div>
						</div>
					</div>
				</section>
				{/* END OF CIRCULATION SECTION */}

				<Contact />
			</Layout>
		)
	}
}
export default LunchTruckPage
