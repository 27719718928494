import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import wrapped01 from '../../images/wrappedslider-01-min.jpg'
import wrapped02 from '../../images/wrappedslider-02-min.jpg'
import wrapped03 from '../../images/wrappedslider-03-min.jpg'
import wrapped04 from '../../images/wrappedslider-04-min.jpg'
import wrapped05 from '../../images/wrappedslider-05-min.jpg'
import wrapped06 from '../../images/wrappedslider-06-min.jpg'
import wrapped07 from '../../images/wrappedslider-07-min.jpg'
import wrapped08 from '../../images/wrappedslider-08-min.jpg'
// import wrapped09 from '../../images/wrappedslider-09-min.jpg'

export default class FullyWrappedSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={wrapped01} className="img-fluid" alt="wrapped Slider 01" />
				</div>
				<div>
					<img src={wrapped02} className="img-fluid" alt="wrapped Slider 02" />
				</div>
				<div>
					<img src={wrapped03} className="img-fluid" alt="wrapped Slider 03" />
				</div>
				<div>
					<img src={wrapped04} className="img-fluid" alt="wrapped Slider 04" />
				</div>
				<div>
					<img src={wrapped05} className="img-fluid" alt="wrapped Slider 05" />
				</div>
				<div>
					<img src={wrapped06} className="img-fluid" alt="wrapped Slider 06" />
				</div>
				<div>
					<img src={wrapped07} className="img-fluid" alt="wrapped Slider 07" />
				</div>
				<div>
					<img src={wrapped08} className="img-fluid" alt="wrapped Slider 08" />
				</div>
			</Swiper>
		)
	}
}
